import React, { useState } from "react";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import "./SocialSubMenu.css";
import logo from "../../images/favicon.ico";
import { Image } from "react-bootstrap";

const Submenu = () => {
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow(!show);
  };

  return (
    <div className="social-media-submenu">
      <button
        className={`toggle-button ${show ? "open" : ""}`}
        onClick={toggle}
      >
        <Image className="logo-submenu" src={logo} alt="logo" />
      </button>
      <ul className={`submenu ${show ? "open" : ""}`}>
        <li>
          <a
            href="https://www.facebook.com/profile.php?id=100088695364963"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook />
          </a>
        </li>
        <li>
          <a href="https://wa.link/d0gkk2" target="_blank" rel="noreferrer">
            <FaWhatsapp />
          </a>
        </li>
        <li>
          <a
            href="https://instagram.com/tiendalove.cr?igshid=YWJhMjlhZTc="
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Submenu;
