import React from "react";
import logo from "../../images/logo-completo.png";

import SocialSubMenu from "../../components/submenus/SocialSubMenu";
import Footer from "../../components/footer/Footer";
import "./CoomingSoon.component.css";

const ComingSoon = () => {
  return (
    <>
      <SocialSubMenu />
      <div className="page-wrapper">
        <div className="container-cm">
          <img src={logo} alt="logo" className="logo-cm" />
          <h1>Muy Pronto</h1>
          <p>Estamos trabajando para darte la mejor experiencia de compra</p>
          <div className="visitanos">
            <h1>Visitanos</h1>
            <p>
              Por el momento puedes encontrarnos en Santo Domingo de Heredia,
              Calle Central 15 agosto
            </p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15717.650617998852!2d-84.0911703!3d9.982731!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0e5de001c5963%3A0x1bfad3ec57373939!2sTienda%20LoveCR!5e0!3m2!1sen!2sca!4v1712296659630!5m2!1sen!2sca"
              width="600"
              height="450"
              className="iframe-map"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="mapa de google maps"
            ></iframe>
          </div>
        </div>
        {/* google map with "visitanos" */}
        <Footer className="footer"/>
      </div>
    </>
  );
};

export default ComingSoon;
