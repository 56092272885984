import React from "react";
import { Routes, Route } from "react-router-dom";

import ComingSoon from "./Pages/cooming soon/CoomingSoon.component";

// const StoreRoute = () => (
//   <>
//     <StoreNavbar />
//     <Outlet />
//   </>
// );

function App() {
  return (
    <Routes>
      {/* Home Page */}
      <Route path="*" element={<ComingSoon />} />
      {/*
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

      <Route element={<StoreRoute />}>

        <Route path="*" element={<h1>404: Not Found</h1>} />

        <Route path="/admin/userlist" element={<UserListPage />} />
        <Route path="/admin/user/:id/edit" element={<UserEditPage />} />
        <Route path="admin/productlist" element={<ProductListPage />} />
        <Route
          path="admin/productlist/page/:pageNumber"
          element={<ProductListPage />}
          exact
        />
        <Route path="admin/product/:id/edit" element={<ProductEditPage />} />
        <Route path="admin/orderlist" element={<OrderListPage />} />
        <Route
          path="admin/orderlist/page/:pageNumber"
          element={<OrderListPage />}
          exact
        />

        <Route path="/profile" element={<ProfilePage />} />
        <Route
          path="/profile/page/:pageNumber"
          element={<ProfilePage />}
          exact
        />
        <Route path="/profile/edit" element={<EditProfile />} />
        <Route
          path="/profile/password-change"
          element={<ProfileEditPasswordPage />}
        />
        <Route path="/shipping" element={<ShippingPage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/placeorder" element={<PlaceOrderPage />} />
        <Route path="/order/:id" element={<OrderPage />} />
ƒ
        <Route path="/store" element={<Store />} />
        <Route path="/store/page/:pageNumber" element={<Store />} exact />
        <Route path="/store/search/:keyword" element={<Store />} exact />
        <Route
          path="/store/search/:keyword/page/:pageNumber"
          element={<Store />}
        />
        <Route
          path="/store/search/:keyword/category/:category"
          element={<Store />}
        />
        <Route path="/store/search/:keyword/brand/:brand" element={<Store />} />
        <Route
          path="/store/search/:keyword/category/:category/brand/:brand"
          element={<Store />}
        />
        <Route
          path="/store/search/:keyword/category/:category/brand/:brand/page/:pageNumber"
          element={<Store />}
        />
        <Route
          path="/store/search/:keyword/page/:pageNumber/brand/:brand"
          element={<Store />}
        />
        <Route
          path="/store/search/:keyword/page/:pageNumber/category/:category"
          element={<Store />}
        />
        <Route
          path="/store/search/:keyword/page/:pageNumber/category/:category/brand/:brand"
          element={<Store />}
        />
        <Route path="/store/category/:category" element={<Store />} />
        <Route
          path="/store/category/:category/page/:pageNumber"
          element={<Store />}
        />
        <Route path="/store/brand/:brand" element={<Store />} />
        <Route
          path="/store/brand/:brand/page/:pageNumber"
          element={<Store />}
        />
        <Route
          path="/store/brand/:brand/category/:category"
          element={<Store />}
        />
        <Route
          path="/store/brand/:brand/category/:category/page/:pageNumber"
          element={<Store />}
        />
        <Route
          path="/store/category/:category/brand/:brand"
          element={<Store />}
        />
        <Route
          path="/store/category/:category/brand/:brand/page/:pageNumber"
          element={<Store />}
        />
        <Route path="/store/product/:_id" element={<DetailProductScreen />} />
        <Route path="/cart">
          <Route path=":id" element={<CartLayout />} />
          <Route path="" element={<CartLayout />} />
        </Route>
      </Route>
      */}
    </Routes>
  );
}

export default App;
