import React from "react";
import { BsInstagram } from "react-icons/bs";
import { SiGooglemaps } from "react-icons/si";
import { AiFillPhone, AiOutlineMail } from "react-icons/ai";
import logo from "../../images/logo-completo.png";
import "./Footer.css";
import { Col, Container, Image, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="container-footer">
      <Container>
        <Row className="contact">
          <Col className="text-center py-3">
            <h3>Contáctanos</h3>
            <p>
              <SiGooglemaps /> Santo Domingo de Heredia Calle Central 15 agosto{" "}
              <br />
              <AiFillPhone /> (506) 8947 7414
              <br />
              <AiOutlineMail />{" "}
              <a href="mailto:tiendalovecr@gmail.com">sales@tiendalovecr.com</a>
            </p>
          </Col>
        </Row>
        <Row className="footer-social">
          <Col className="text-center py-3">
            <Image src={logo} alt="logo" className="footer-logo" />
          </Col>
          <Col className="text-center py-3">
            <a
              href="https://instagram.com/tiendalove.cr?igshid=YWJhMjlhZTc="
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram />
              <span className="instagram">Siguenos en Instagram</span>
            </a>
          </Col>
          <Col className="text-center py-3">
            Copyrigth &copy; Tienda LoveCR 2022
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
